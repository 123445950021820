.appoinment-wrapper {
  padding-bottom: 120px;
  width: 40%;
  height: 750px;
  background-color: #ffffffdf;
  border-radius: 15px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  /* margin-right: -15px;
    margin-left: -15px; */
}
.row1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.appoinment-form form input,
.appoinment-form form textarea {
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 5.288px 11.876px 40px 0 rgb(0 0 0 / 9%);
  height: 68px;
  border: none;
  line-height: 68px;
  width: 100%;
  color: #666;
  font-size: 16px;
  font-weight: 300;
  margin-top: 30px;
  padding: 0 20px;
}
.form_pad {
  padding: 6%;
}
.btn_cross {
  background-color: transparent;
  border: 0px solid #fff;
  cursor: pointer;
  font-size: 30px;
  color: #000;
}
textarea {
  min-height: 300px;
  overflow: auto;
  resize: vertical;
}

.form-btn {
  width: 200px;
}

.form-btn:hover {
  border: 1px solid #54bfdc !important;
}
@media screen and (min-width: 700px) {
}
@media screen and (max-width: 700px) {
}
