.gradient-form {
    background-color: #000;
}

.card-img {
    width: 185px;
}
.btn-floating {
    font-size: 40px !important;
}

.loginbtn {
    width: 38px !important;
    height: 38px !important;
    font-size: 27px !important;
    margin: 0 8px !important;
    line-height: 0px !important;
    padding: 4px;
    border: none;
}

.gradient-custom-2 {
    background: #c3ddfd;
    background: linear-gradient(to right, #1a56db, #1e429f, #76a9fa, #54BFDC);
}
    
/* for mobile responsive */
@media (min-width: 769px) {
    .gradient-custom-2 {
        border-top-right-radius: .3rem;
        border-bottom-right-radius: .3rem;
    }
}