.service-wrapper {
    background: #000 !important;
    padding: 120px 0;
    color: #fff;
}

.section-title {
    margin-bottom: 27px;
}

.section-title h1 {
    line-height: 1;
    font-family: "Josefin Sans", sans-serif;
    font-weight: 600;
}

.single-service-box {
    border: 1px solid #54BFDC;
    border-radius: 5px;
    padding: 60px 47px 70px;
    margin-top: 30px;
    transition: .5s;
    background: transparent;
    text-align: center;
}

.single-service-box:hover {
    background: #FFF;
    cursor: cell;
    border-color: transparent;
    color: #000;
}

.service-icon {
    margin-bottom: 44px;
}

.single-service-box h3 {
    margin-bottom: 12px;
    font-size: 24px;
    font-family: "Josefin Sans", sans-serif;
    line-height: 36px;
}

.single-service-box p {
    /* color: #d7d3d3!important; */
    font-family: 'Roboto Slab', serif;
}

.single-service-box a {
    color: #54BFDC !important;
}

.single-service-box a:hover {
    color: #000 !important;
}
