@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&family=Lora:ital,wght@0,400;0,500;0,600;1,400&family=Roboto+Slab:wght@300;400;500;600;700&display=swap');

.container-head {
    background: #000;
    border-radius: 10px;
    transition: all .5s ease-in-out;
    padding: 0 30px;
    margin-top: 25px;
}

.head-bg {
    background-color: #000;
}

.list-item {
    padding: 25px 0;
    display: inline-block;
    color: #fff;
    text-transform: capitalize;
    padding-left: 40px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Roboto Slab', serif;
}

.active-item {
    color: #54BFDC;
}

.list-item:hover {
    color: #54BFDC;
}

.ml-5 {
    margin-left: 20px !important;
}

.ml-5:hover {
    border-color: #ffffff !important;
}

.userName {
    color: #000;
    margin-left: 5px;
}

.btn {
    margin-left: 15px;
    margin-right: 15px;
}
.image-data{
    height: 50px;
}
.btn-danger {
    font-family: Raleway-SemiBold;
    font-size: 18px;
    font-weight: bold;
    color: #54BFDC ;
    letter-spacing: 1px;
    line-height: 15px;
    border: 2px solid #54BFDC ;
    border-radius: 40px;
    background: transparent;
    transition: all 0.3s ease 0s;
  }
  
  .btn-danger:hover {
    color: #FFF;
    background: #54BFDC ;
    border: 2px solid #54BFDC ;
  }
.btn-info {
    font-family: Raleway-SemiBold;
    font-size: 18px;
    font-weight: bold;
    color: #54BFDC ;
    letter-spacing: 1px;
    line-height: 15px;
    border: 2px solid #54BFDC ;
    border-radius: 40px;
    background: transparent;
    transition: all 0.3s ease 0s;
  }
  
  .btn-info:hover {
    color: #FFF;
    background: #54BFDC ;
    border: 2px solid #54BFDC ;
  }



  /* for mobile responsive */

@media (max-width: 992px) {
    /* 2: Navbar */
    .btn {
        margin-left: 57px;
        margin-right: 15px;
        margin-bottom: 20px;
    }

    .container-head {
        max-width: 80%;
    }
}


@media (max-width: 426px) {
    .list-item {
        padding: 15px 0;
    }

    .btn {
        margin: 15px auto;
    }
}