.appoinment-wrapper {
    padding-bottom: 120px;
    width: 50%;
    height: 750px;
    background-color: #ffffff ;
    border-radius: 15px;
    box-shadow: 5.288px 11.876px 40px 0 rgb(0 0 0 / 15%);
  }

  @media screen and (max-width: 1000px) {
    .appoinment-wrapper {
      width: 70%;
    }
  }

  @media screen and (max-width: 800px) {
    .appoinment-wrapper {
      width: 90%;
    }
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
    /* margin-right: -15px;
      margin-left: -15px; */
  }
  .row1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .appoinment-form form input,
  .appoinment-form form textarea {
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 5.288px 11.876px 40px 0 rgb(0 0 0 / 9%);
    height: 68px;
    border: none;
    line-height: 68px;
    width: 100%;
    color: #666;
    font-size: 16px;
    font-weight: 300;
    margin-top: 30px;
    padding: 0 20px;
  }
  .form_pad {
    padding: 6%;
  }
  .btn_cross {
    background-color: transparent;
    border: 0px solid #fff;
    cursor: pointer;
    font-size: 30px;
    color: #9FA0A1;
  }
  textarea {
    min-height: 300px;
    overflow: auto;
    resize: vertical;
  }
  
  .form-btn {
    width: 200px;
  }
  
  .form-btn:hover {
    border: 1px solid #B0CB1F !important;
  }
  @media screen and (min-width: 700px) {
  }
  @media screen and (max-width: 700px) {
  }
  