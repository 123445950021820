.appoint-container {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 150vh;
    z-index: 999;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    overflow: hidden;
    display: grid;
    place-items: center;
  }
  
  .appoint-subcontainer {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: grid;
    place-items: center;
}


.appoint-button {
    font-size: 30px;
    color: #000;
    position: absolute;
    left: 67%;
    top: 22%;
    border-radius: 50%;
    border: 0px;
    background-color: transparent;
}